<template>
  <div class="box">
    <div class="box-header">
      <h3 class="box-title">TradingBot parameters</h3>
    </div>
    <div class="box-body">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form @submit.prevent="handleSubmit(onSubmit())">
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Market" rules="required" tag="div">
            <div class="row">
              <label class="col-md-5" for="Symbol">Market</label>
              <div class="col-md-7">
                <v-select v-model="form.Symbol" :options="symbols" :reduce="s => s.symbol" label="symbol"
                  placeholder="Select Symbol" style="min-width: 180px;"></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Algorithm" rules="required" tag="div">
            <div class="row">
              <label class="col-md-5" for="Algo">Algorithm</label>
              <div class="col-md-7">
                <select v-model="form.Algo" class="form-control" id="Algo">
                  <option value="DayTrading">DayTrading</option>
                  <option value="MovingAverageCrossover">MovingAverageCrossover</option>
                  <option value="RelativeStrengthIndex">RelativeStrengthIndex</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Order Type" rules="required" tag="div"
            vid="OrderType">
            <div class="row">
              <label class="col-md-5" for="OrderType">Order Type</label>
              <div class="col-md-7">
                <select v-model="form.OrderType" class="form-control" id="OrderType">
                  <option value="limit">Limit</option>
                  <option value="market">Market</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" class="form-group" name="Actions" rules="required" tag="div"
            vid="Actions">
            <div class="row">
              <label class="col-md-5" for="Actions">Actions</label>
              <div class="col-md-7">
                <select v-model="form.Actions" class="form-control" id="Actions">
                  <template v-if="form.OrderType === 'limit'">
                    <option value="sell">Sell</option>
                    <option value="buy">Buy</option>
                  </template>
                  <template v-if="form.OrderType === 'market'">
                    <option value="sellbuy">Buy and Sell</option>
                  </template>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" class="form-group" name="Amount" :rules="baseCurrencyAmtRules"
            tag="div" v-if="form.OrderType === 'market' || (form.OrderType === 'limit' && form.Actions === 'sell')">
            <div class="row">
              <label class="col-md-5" for="BaseCurrencyAmt">{{ getCurrency(0) }} Amount</label>
              <div class="col-md-7">
                <input id="BaseCurrencyAmt" v-model="form.BaseCurrencyAmt" class="form-control" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Amount" :rules="quoteCurrencyAmtRules"
            tag="div" v-if="form.OrderType === 'market' || (form.OrderType === 'limit' && form.Actions === 'buy')">
            <div class="row">
              <label class="col-md-5" for="QuoteCurrencyAmt">{{ getCurrency(1) }} Amount</label>
              <div class="col-md-7">
                <input id="QuoteCurrencyAmt" v-model="form.QuoteCurrencyAmt" class="form-control" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Risk Profile" rules="required" tag="div"
            vid="RiskProfile">
            <div class="row">
              <label class="col-md-5" for="RiskProfile">Risk Profile</label>
              <div class="col-md-7">
                <select v-model="form.RiskProfile" class="form-control" id="RiskProfile">
                  <option value="low">low</option>
                  <option value="high">high</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Segments" :rules="segementsRules" tag="div"
            v-if="form.RiskProfile === 'low'">
            <div class="row">
              <label class="col-md-5" for="Segments">Segments</label>
              <div class="col-md-7">
                <input id="Segments" v-model="form.Segments" class="form-control" type="number" min="1" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Immediate" rules="required" tag="div"
            vid="Immediate">
            <div class="row">
              <label class="col-md-5" for="Immediate">Immediate</label>
              <div class="col-md-7">
                <select v-model="form.Immediate" class="form-control" id="Immediate">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Step in %" rules="required|min_value:0"
            tag="div" v-if="form.OrderType === 'market' && form.Immediate === '0' || form.Immediate === 'false'">
            <div class="row">
              <label class="col-md-5" for="InitialPercentage">Step in % diff</label>
              <div class="col-md-7">
                <input id="InitialPercentage" v-model="form.InitialPercentage" class="form-control" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Trade Trigger"
            rules="required_if:OrderType,market|min_value:0.25" tag="div" v-if="form.OrderType === 'market'">
            <div class="row">
              <label class="col-md-5" for="TradeTrigger">Trade Trigger %</label>
              <div class="col-md-7">
                <input id="TradeTrigger" v-model="form.TradeTrigger" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Profit Percentage" rules="required"
            tag="div">
            <div class="row">
              <label class="col-md-5" for="ProfitPercentage">Profit Percentage</label>
              <div class="col-md-7">
                <input id="ProfitPercentage" v-model="form.ProfitPercentage" class="form-control" type="number"
                  min="0" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" class="form-group" name="Abort Condition" rules="required" tag="div">
            <div class="row">
              <label class="col-md-5" for="AbortCondition">Abort Condition</label>
              <div class="col-md-7">
                <select v-model="form.AbortCondition" class="form-control" id="AbortCondition">
                  <option value="5">5% loss</option>
                  <option value="10">10% loss</option>
                  <option value="30">30% loss</option>
                  <option value="60">60% loss</option>
                  <option value="90">90% loss</option>
                  <option value="none">none</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <div class="text-right">
            <button :disabled="isLoading" v-if="editBot" type="button" @click="cancelEdit()"
              class="btn btn-danger btn-flat mr-8">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary btn-flat" :disabled="isLoading">
              {{ editBot ? 'Update' : 'Create' }}
              <span v-if="isLoading">
                <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </span>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SAVE_SYMBOLS } from "@/store/keys";

export default {
  name: "SpawnTradeBot",
  props: {
    symbol: {
      type: String,
      default: null,
    },
    editBot: {
      type: Object,
      default: null,
    },
    merchant: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        XChangeNameID: 13,
        MerchantID: "",
        APIKey: "",
        Algo: "DayTrading",
        OrderType: "market",
        Symbol: "",
        Actions: "sellbuy",
        BaseCurrencyAmt: 0,
        QuoteCurrencyAmt: 0,
        RiskProfile: "low",
        Segments: 1,
        TradeTrigger: 0.25,
        AbortCondition: 10,
        Immediate: 0,
        InitialPercentage: 0,
        ProfitPercentage: 1,
      },
      isLoading: false,
      balanceList: [],
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "merchantsWithKeys",
      "symbols",
    ]),
    baseCurrencyAmtRules() {
      const symbol = this._.find(this.symbols, { symbol: this.form.Symbol });
      if (symbol) {
        if (Number(this.form.BaseCurrencyAmt) === 0 && (Number(this.form.BaseCurrencyAmt) > 0 || Number(this.form.QuoteCurrencyAmt) === 0)) {
          return "required_if:Actions,sell,sellbuy|min_value:" + symbol.baseMinSize;
        }
      }
      return "required_if:Actions,sell,sellbuy";
    },
    quoteCurrencyAmtRules() {
      const symbol = this._.find(this.symbols, { symbol: this.form.Symbol });
      if (symbol) {
        if (Number(this.form.QuoteCurrencyAmt) > 0) {
          return "required_if:Actions,buy,sellbuy|min_value:" + symbol.quoteMinSize;
        }
      }
      return "required_if:Actions,buy,sellbuy";
    },
    segementsRules() {
      if (this.form.RiskProfile === "high") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form = {
          ...this.form,
          Segments: 1,
        };
        return "required_if:RiskProfile,high|numeric|min_value:1";
      } else {
        if (this.form.Segments < 2) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form = {
            ...this.form,
            Segments: 2,
          };
        }
        return "required_if:RiskProfile,low|numeric|min_value:2";
      }
    },
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.fillBalance(newVal.Symbol);
        if (newVal.OrderType === "market") {
          this.form.Actions = "sellbuy";
        } else {
          if (this.form.Actions === "sellbuy") {
            this.form.Actions = "sell";
          }
        }
      },
      deep: true,
    },
    symbol: {
      handler(val) {
        this.$emit("cancelEdit");
        this.form.Symbol = val;
        this.fillBalance(val);
      },
      deep: true,
      immediate: true,
    },
    merchant: {
      handler(val) {
        if (val) {
          this.form.MerchantID = val.ID;
          this.form.APIKey = val.APIKey;
          this.fetchMerchantBalancesList(!!this.form.Symbol);
        }
      },
      immediate: true,
    },
    editBot(val) {
      if (val) {
        this.form = {
          ...this.form,
          XChangeNameID: 13,
          MerchantID: this.merchant.ID,
          APIKey: this.merchant.APIKey,
          Algo: val.Algo,
          OrderType: val.OrderType,
          Symbol: val.Symbol,
          Actions: val.Actions,
          BaseCurrencyAmt: val.InitialBaseAmount,
          QuoteCurrencyAmt: val.InitialQuoteAmount,
          RiskProfile: val.RiskProfile,
          Segments: val.Segments,
          TradeTrigger: val.TradeTrigger,
          AbortCondition: val.AbortCondition,
          InitialPercentage: val.InitialPercentage,
          ProfitPercentage: val.ProfitPercentage,
          Immediate: val.Immediate ? 1 : 0,
        };
      }
    },
  },
  methods: {
    clearForm() {
      this.form = {
        ...this.form,
        XChangeNameID: 13,
        MerchantID: "",
        APIKey: "",
        Algo: "DayTrading",
        OrderType: "market",
        Symbol: "",
        Actions: "sellbuy",
        BaseCurrencyAmt: 0,
        QuoteCurrencyAmt: 0,
        RiskProfile: "low",
        Segments: 1,
        TradeTrigger: 0.25,
        AbortCondition: 10,
        Immediate: 0,
        InitialPercentage: 0,
        ProfitPercentage: 1,
      };
    },
    fillBalance(val) {
      if (val && this.balanceList.length > 0) {
        const currencies = val.split("-");
        const baseAmount = this._.find(this.balanceList, { LongName: currencies[0] });
        if (baseAmount) {
          this.form.BaseCurrencyAmt = baseAmount.TotalBalance;
        }
        const quoteAmount = this._.find(this.balanceList, { LongName: currencies[1] });
        if (quoteAmount) {
          this.form.QuoteCurrencyAmt = quoteAmount.TotalBalance;
        }
      } else {
        this.form.BaseCurrencyAmt = 0;
        this.form.QuoteCurrencyAmt = 0;
      }
    },
    getCurrency(index) {
      if (this.form.Symbol) {
        return this.form.Symbol.split("-")[index];
      }
      return "";
    },
    fetchSymbols() {
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$http.get(`${apiBaseUrl}/TradeBot/symbols`, {
        params: {
          key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
        },
      }).then(response => {
        const symbolsList = response.data.data;
        this.$store.commit(SAVE_SYMBOLS, symbolsList);
      }).catch(_error => {
      });
    },
    fetchMerchantBalancesList(isFillBalance = false) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=BalanceOverview`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id: this.merchant.ID,
        },
      }).then(response => {
        if (response.status === 200) {
          this.balanceList = response.data.data;
          if (isFillBalance) {
            this.fillBalance(this.form.Symbol);
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    onSubmit() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          if (!this.form.MerchantID || !this.form.APIKey) {
            this.$toast.fire("", "Please select merchant", "error");
          } else {
            const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
            const url = this.editBot ? apiBaseUrl + "/TradeBot/bot/update" : apiBaseUrl + "/TradeBot/bot/initiate";
            let payload = { ...this.form };
            if (this.editBot) {
              payload = {
                ...payload,
                BotID: this.editBot.ID,
              };
            }
            this.$http.get(url, {
              params: {
                ...payload,
                Immediate: payload.Immediate === "0" ? "false" : "true",
                key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
              },
            }).then(response => {
              this.$emit("refresh", this.editBot ? this.editBot.ID : response.data.data.ID);
              if (this.editBot) {
                this.$emit("cancelEdit");
              }
            }).catch(error => {
              if (error.data?.reason) {
                this.$toast.fire("", error.data.reason, "error");
              }
            });
          }
        }
      });
    },
    cancelEdit() {
      this.clearForm();
      this.$emit("cancelEdit");
    },
  },
  beforeMount() {
    this.fetchSymbols();
  },
};
</script>
