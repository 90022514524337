<template>
  <div class="box box-default">
    <div class="box-header">
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <h3 class="box-title">Spot Market Automatic Trading Bot</h3>
        <div class="flex">
          <v-select v-model="merchant" :options="merchantsList" label="label" placeholder="Select Merchant"
            style="min-width: 200px; max-width: 200px;" :clearable="false"></v-select>
          <router-link class="btn btn-default btn-flat btn-sm ml-8" :to="{ name: 'trade-bots' }">Back</router-link>
        </div>
      </div>
    </div>
    <div class="box-body" style="background-color: #f9f9f9;">
      <div class="row mb-8">
        <div class="col-lg-3">
          <Tickers :accountInfo="user" @setSymbol="setSymbol" />
        </div>
        <div class="col-lg-6">
          <TradeChart :merchant="merchant" :botID="botID" :sys="symbol" />
        </div>
        <div class="col-lg-3">
          <SpawnTradeBot :merchant="merchant" @refresh="refresh" :symbol="symbol" :editBot="editBot"
            @cancelEdit="cancelEdit()" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <MerchantBots :merchant="merchant" @setEditBot="setEditBot" @setBotID="setBotID" :refresh="refreshBots" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SAVE_MERCHANTS_WITH_KEYS } from "@/store/keys";
import MerchantBots from "@/components/tradebot/MerchantBots.vue";
import SpawnTradeBot from "@/components/tradebot/SpawnTradeBot.vue";
import Tickers from "@/components/tradebot/Tickers.vue";
import TradeChart from "@/components/tradebot/Charts/TradeChart.vue";

export default {
  name: "MerchantTradeBot",
  components: {
    MerchantBots,
    SpawnTradeBot,
    Tickers,
    TradeChart,
  },
  data() {
    return {
      botID: null,
      user: null,
      merchant: null,
      editBot: null,
      refreshBots: null,
      symbol: null,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "merchantsWithKeys",
      "symbols",
    ]),
    merchantsList() {
      return this.merchantsWithKeys.map(m => {
        return {
          ...m,
          label: m.ID + " - " + m.OwnerNickName,
        };
      });
    },
  },
  watch: {
    merchant(val) {
      if (val) {
        this.fetchMerchantDetail(val.ID);
      } else {
        this.user = null;
      }
    },
  },
  methods: {
    fetchMerchantDetail(id) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=UserDetail`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          ID: id,
        },
      }).then(response => {
        if (response.status === 200) {
          this.user = Object.assign({}, response.data.data);
        }
      }).catch(_error => { });
    },
    fetchMerchantsList() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=MerchantsListWithKeys`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        const merchants = response.data.data.filter(m => m.FinalVerification === 1);
        this.$store.commit(SAVE_MERCHANTS_WITH_KEYS, merchants);
        if (!this.merchant) {
          this.setValues();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    setEditBot(bot) {
      this.editBot = bot;
    },
    setBotID(botID) {
      this.botID = botID;
    },
    cancelEdit() {
      this.editBot = null;
    },
    refresh(botID) {
      this.refreshBots = botID;
    },
    setSymbol(symbol) {
      this.symbol = symbol;
    },
    setValues() {
      if (Object.keys(this.$route.query).length > 0) {
        if (this.$route.query.merchant && this.merchantsList.length > 0) {
          this.merchant = this.merchantsList.find(m => m.ID === parseInt(this.$route.query.merchant));
        }
        if (this.$route.query.symbol) {
          this.setSymbol(this.$route.query.symbol);
        }
        if (this.$route.query.bot) {
          this.setBotID(this.$route.query.bot);
        }
      }
    },
  },
  beforeMount() {
    this.fetchMerchantsList();
    this.setValues();
  },
};
</script>
