<template>
  <div class="box">
    <div class="box-body">
      <div class="mb-8">
        <input v-model="keyword" class="form-control " type="text" placeholder="Search..." />
      </div>
      <div class="table-responsive" style="height: 65rem;">
        <table class="table table-condensed table-striped mb-8">
          <thead>
            <tr>
              <th>
                <span class="cursor-pointer mr-2"
                  @click="sortKey = 'symbol'; sortOrder = sortOrder === 'desc' ? 'asc' : 'desc'">
                  <span class="inline-flex items-center">
                    Pair
                    <span class="ml-1" v-if="sortKey === 'symbol'">
                      <i v-if="sortOrder === 'desc'" class="fa fa-sort-amount-desc" />
                      <i v-else class="fa fa-sort-amount-asc" />
                    </span>
                  </span>
                </span>
                <span class="cursor-pointer"
                  @click="sortKey = 'vol'; sortOrder = sortOrder === 'desc' ? 'asc' : 'desc'">
                  <span class="inline-flex items-center">
                    Volume
                    <span class="ml-1" v-if="sortKey === 'vol'">
                      <i v-if="sortOrder === 'desc'" class="fa fa-sort-amount-desc" />
                      <i v-else class="fa fa-sort-amount-asc" />
                    </span>
                  </span>
                </span>
              </th>
              <th>
                <span class="mr-2">Price</span>
                <span class="cursor-pointer"
                  @click="sortKey = 'changeRate'; sortOrder = sortOrder === 'desc' ? 'asc' : 'desc'">
                  <span class="inline-flex items-center">
                    Change (24 H)
                    <span class="ml-1" v-if="sortKey === 'changeRate'">
                      <i v-if="sortOrder === 'desc'" class="fa fa-sort-amount-desc" />
                      <i v-else class="fa fa-sort-amount-asc" />
                    </span>
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="filteredTickers.length">
              <tr v-for="ticker in filteredTickers" :key="ticker.symbol" class="pointer"
                @click="selectSymbol(ticker.symbol)">
                <td>
                  <span class="d-block text-bold">{{ ticker.symbol }}</span>
                  <span class="d-block">{{ ticker.vol.toFixed(8) }}</span>
                </td>
                <td>
                  <span class="d-block">{{ ticker.last.toFixed(8) + ' ' + getCurrency(ticker.symbol, 1) }}</span>
                  <span :class="[ticker.changeRate < 0 ? 'text-danger' : 'text-success']">
                    {{ (Number(ticker.changeRate) * 100).toFixed(2) }}%
                  </span>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="2">No record found!</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SAVE_TICKERS } from "@/store/keys";

export default {
  name: "Tickers",
  data() {
    return {
      keyword: "",
      sortKey: "symbol",
      sortOrder: "asc",
      isLoading: false,
      interval: null,
    };
  },
  props: {
    accountInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "altCoins",
      "currentUser",
      "tickers",
    ]),
    filteredTickers() {
      let tickersList = this.tickers;
      if (this.accountInfo) {
        const selectedCoins = this.accountInfo.SelectedAltCoins.split(",").filter(Boolean).map(n => Number(n));
        const list = [];
        selectedCoins.forEach(id => {
          const coin = this._.find(this.altCoins.value, { ID: id });
          if (coin) {
            list.push(coin.LongName);
          }
        });
        if (list.length > 0) {
          tickersList = tickersList.filter(s => {
            const parts = s.symbol.split("-");
            return list.indexOf(parts[0]) !== -1 && list.indexOf(parts[1]) !== -1;
          });
        }
      }

      const records = this._.orderBy(tickersList, this.sortKey, this.sortOrder);
      return records.filter(ticker => {
        if (this.keyword && this.keyword !== "") {
          return ticker.symbol.indexOf(this.keyword.toUpperCase()) !== -1;
        }
        return true;
      });
    },
  },
  methods: {
    getCurrency(symbol, index) {
      return symbol.split("-")[index];
    },
    fetchTickers() {
      this.isLoading = true;
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$http.get(`${apiBaseUrl}/TradeBot/allTickers`, {
        params: {
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
        },
      }).then(response => {
        this.isLoading = false;
        let tickersList = response.data.data;
        tickersList = this._.sortBy(tickersList, ["symbol"]);
        this.$store.commit(SAVE_TICKERS, tickersList);
      }).catch(_error => {
        this.isLoading = false;
      });
    },
    selectSymbol(symbol) {
      this.$emit("setSymbol", symbol);
    },
  },
  beforeMount() {
    this.fetchTickers();
    this.interval = setInterval(() => {
      this.fetchTickers();
    }, 60000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
};
</script>
