<template>
  <div>
    <div class="flex align-items-center justify-end mb-8 gap-8 flex-col-sm">
      <div class="dropdown ml-8">
        <a class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
          {{ statuses.filter(s => s.selected).length }} options selected
        </a>
        <div class="dropdown-menu">
          <form>
            <a class="dropdown-item" href="#">
              <label class="d-block">
                <input type="checkbox" class="form-checkbox" v-model="allStatuses" :checked="allStatuses" />
                <span class="ml-3">Select All</span>
              </label>
            </a>
            <a class="dropdown-item" href="#" v-for="(status, index) in statuses" :key="index">
              <label class="d-block">
                <input type="checkbox" class="form-checkbox" v-model="status.selected" :checked="status.selected" />
                <span class="ml-3">{{ status.label }}</span>
              </label>
            </a>
          </form>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="box-body">
        <template v-if="!isLoading">
          <div class="table-responsive">
            <table class="table table-stripped table-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Bot</th>
                  <th>Market</th>
                  <th>Algo</th>
                  <th>Type</th>
                  <th>Invested</th>
                  <th>Invested</th>
                  <th>Total Invested</th>
                  <th>Current</th>
                  <th>Current</th>
                  <th>Total Current</th>
                  <th>Profit Target</th>
                  <th>Actual</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="records.length">
                  <template v-for="(bot, index) in records">
                    <tr :key="bot.ID">
                      <td>
                        <span class="flex pointer text-success" @click="bot.isExpanded = !bot.isExpanded">
                          <svg v-if="!bot.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"></path>
                          </svg>
                          <svg v-if="bot.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"></path>
                          </svg>
                        </span>
                      </td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">TB{{ bot.ID }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">{{ bot.Symbol }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">{{ bot.Algo }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">{{ bot.OrderType }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">
                        {{ bot.InitialBaseAmount + ' ' + bot.BaseCurrencyLongName }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">
                        {{ bot.InitialQuoteAmount + ' ' + bot.QuoteCurrencyLongName }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">
                        {{ ((bot.InitialBaseAmount * bot.Price) + bot.InitialQuoteAmount).toFixed(8) + ' ' +
            bot.QuoteCurrencyLongName }}
                      </td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">
                        {{ bot.FinalBaseAmount + ' ' + bot.BaseCurrencyLongName }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">
                        {{ bot.FinalQuoteAmount + ' ' + bot.QuoteCurrencyLongName }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">
                        <span v-if="bot.LastBid">
                          {{ ((bot.FinalBaseAmount * bot.LastBid) + bot.FinalQuoteAmount).toFixed(8) + ' ' +
            bot.QuoteCurrencyLongName }}
                        </span>
                      </td>
                      <td @click="setBotID(bot.ID)">{{ bot.ProfitPercentage + "%" }}</td>
                      <td @click="setBotID(bot.ID)" class="no-wrap pointer">
                        <span v-if="bot.LastBid" class="no-wrap pointer">
                          <span class="mr-8">
                            {{ difference(bot) }}
                          </span>
                          <span v-html="calculateProfitActual(bot)"></span>
                        </span>
                      </td>
                      <td @click="setBotID(bot.ID)" class="no-wrap text-uppercase">
                        <span>{{ bot.Status }}</span>
                      </td>
                      <td class="no-wrap">
                        <button class="btn btn-danger btn-flat btn-sm ml-8"
                          v-if="bot.Status === 'running' || bot.Status === 'pending'" @click="cancelBot(bot)">
                          Cancel
                        </button>
                        <button type="button" v-if="bot.Status === 'running'"
                          class="btn btn-danger btn-flat btn-sm ml-8" @click="pauseBot(bot)">
                          Pause
                        </button>
                        <button type="button" v-if="bot.Status === 'paused'"
                          class="btn btn-primary btn-flat btn-sm ml-8" @click="resumeBot(bot)">
                          Resume
                        </button>
                        <button type="button" v-if="isEditable(bot)" class="btn btn-primary btn-flat btn-sm ml-8"
                          @click="setEditBot(bot)">
                          Edit
                        </button>
                      </td>
                    </tr>
                    <template v-if="bot.isExpanded">
                      <tr :key="'detail_' + bot.ID">
                        <td colspan="15">
                          <table class="table table-condensed table-striped">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Order ID</th>
                                <th>Base Currency</th>
                                <th>Quote Currency</th>
                                <th>Action</th>
                                <th>Amount</th>
                                <th>Initial Price</th>
                                <th>Calculated Initial Price</th>
                                <th>Price</th>
                                <th>Calculated Price</th>
                                <th>Total</th>
                                <th>Percentage Change</th>
                                <th>Status</th>
                                <th>Market Condition</th>
                                <th>Comments</th>
                                <th>Created On</th>
                                <th>Confirmed On</th>
                                <th>ExChange Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="bot.tbAudits && bot.tbAudits.length > 0">
                                <tr
                                  v-for="audit in bot.tbAudits.slice(bot.meta.offset, bot.meta.offset + bot.meta.limit)"
                                  :key="audit.ID">
                                  <td>{{ audit.ID }}</td>
                                  <td>{{ audit.OrderID }}</td>
                                  <td>{{ audit.BaseCurrency }}</td>
                                  <td>{{ audit.QuoteCurrency }}</td>
                                  <td>{{ audit.Action }}</td>
                                  <td class="no-wrap">{{ audit.Amount + ' ' + audit.BaseCurrency }}</td>
                                  <td class="no-wrap">{{ audit.InitialPrice + ' ' + audit.QuoteCurrency }}</td>
                                  <td class="no-wrap">
                                    <span v-if="audit.Action === 'buy'">
                                      {{ audit.CalculatedInitialPrice + ' ' + currency(audit) }}
                                    </span>
                                  </td>
                                  <td class="no-wrap">{{ audit.Price + ' ' + audit.QuoteCurrency }}</td>
                                  <td class="no-wrap">
                                    <span v-if="audit.Action === 'buy'">
                                      {{ audit.CalculatedPrice + ' ' + currency(audit) }}
                                    </span>
                                  </td>
                                  <td class="no-wrap">{{ audit.Total + ' ' + audit.QuoteCurrency }}</td>
                                  <td>
                                    <span
                                      :class="`label ${audit.PercentageChange < 0 ? 'label-danger' : 'label-success'}`">
                                      {{ audit.PercentageChange + '%' }}
                                    </span>
                                  </td>
                                  <td>{{ audit.Status }}</td>
                                  <td>{{ audit.MarketCondition }}</td>
                                  <td>{{ audit.Comments }}</td>
                                  <td class="no-wrap">{{ audit.CreatedOn }}</td>
                                  <td class="no-wrap">{{ audit.ConfirmedOn }}</td>
                                  <td class="no-wrap">{{ audit.ExchangeDate }}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr>
                                  <td colspan="18">No record found!</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                          <template v-if="bot.tbAudits && bot.tbAudits.length > 0">
                            <pagination :meta="bot.meta" @pageChange="(page) => updateFoldPage(page, index)">
                            </pagination>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="15" class="text-center">No record found!</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-if="meta && meta.total > 0">
            <Pagination :meta="meta" @pageChange="updatePage"></Pagination>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center justify-center">
            <i class="fa fa-spinner fa-4x fa-spin"></i>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";

export default {
  name: "MerchantTradeBots",
  components: {
    Pagination,
  },
  props: {
    merchant: {
      type: Object,
      default: null,
    },
    refresh: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      records: [],
      editBot: null,
      statuses: [
        {
          value: "pending",
          label: "Pending",
          selected: true,
        },
        {
          value: "running",
          label: "Running",
          selected: true,
        },
        {
          value: "paused",
          label: "Paused",
          selected: true,
        },
        {
          value: "completed",
          label: "Completed",
          selected: false,
        },
        {
          value: "aborted",
          label: "Aborted",
          selected: false,
        },
        {
          value: "cancelled",
          label: "Cancelled",
          selected: false,
        },
      ],
      allStatuses: false,
      webSocket: null,
      isCloseSocket: false,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
    ]),
  },
  watch: {
    refresh(val) {
      if (val !== null) {
        this.fetchMerchantTrades();
      }
    },
    merchant: {
      handler(val) {
        if (val) {
          this.fetchMerchantTrades();
        }
      },
      immediate: true,
    },
    statuses: {
      handler(newVal) {
        this.fetchMerchantTrades();
      },
      deep: true,
    },
    allStatuses(newVal) {
      this.statuses = this.statuses.map(s => {
        return {
          ...s,
          selected: newVal,
        };
      });
      this.fetchMerchantTrades();
    },
  },
  methods: {
    isEditable(record) {
      return ["paused"].indexOf(record.Status) !== -1;
    },
    currency(record) {
      return record.Action === "sell" ? record.QuoteCurrency : record.BaseCurrency;
    },
    updateFoldPage(page, index) {
      const record = this.records[index];
      this.records.splice(index, 1, {
        ...record,
        meta: {
          ...record.meta,
          page,
          offset: (page - 1) * record.meta.limit,
        },
      });
    },
    updatePage(page) {
      this.fetchMerchantTrades(page);
    },
    fetchMerchantTrades(page = 1) {
      this.isLoading = true;
      if (this.merchant) {
        const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
        this.$http.get(`${apiBaseUrl}/TradeBot/bots/all`, {
          params: {
            key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            MerchantID: this.merchant.ID,
            APIKey: this.merchant.APIKey,
            page,
            Status: this.statuses.filter(s => s.selected).map(s => s.value).join(","),
            limit: this.meta.limit,
            order: this.sortKey,
          },
        }).then(response => {
          this.isLoading = false;
          this.meta = response.data.data.meta;
          this.records = response.data.data.records;
          this.records = this.records.map(r => {
            const meta = {
              total: r.tbAudits ? r.tbAudits.length : 0,
              limit: 15,
              page: 1,
              offset: 0,
            };
            return {
              ...r,
              isExpanded: false,
              meta,
            };
          });
        }).catch(_error => {
          this.isLoading = false;
        });
      }
    },
    difference(bot) {
      return (((bot.FinalBaseAmount * bot.LastBid) + bot.FinalQuoteAmount) -
        ((bot.InitialBaseAmount * bot.Price) + bot.InitialQuoteAmount)).toFixed(8) + " " +
        bot.QuoteCurrencyLongName;
    },
    calculateProfitActual(bot) {
      const initial = (Number(bot.InitialBaseAmount) * Number(bot.Price)) + Number(bot.InitialQuoteAmount);
      const final = (Number(bot.FinalBaseAmount) * Number(bot.LastBid)) + Number(bot.FinalQuoteAmount);
      const profitPercentage = (100 * ((final - initial) / ((initial + final) / 2))).toFixed(2);
      return `<span class="label ${profitPercentage > 0 ? "label-success" : "label-danger"}">${profitPercentage}%</span>`;
    },
    cancelBot(bot) {
      const index = this._.findIndex(this.records, { ID: bot.ID });
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Cancel Bot",
        text: "Are you sure you want to cancel this bot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.$http.get(`${apiBaseUrl}/TradeBot/bot/cancel`, {
            params: {
              BotID: bot.ID,
              MerchantID: this.merchant.ID,
              APIKey: this.merchant.APIKey,
              key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            },
          }).then(response => {
            // update status
            if (response.status === 200) {
              if (index !== -1) {
                const statusList = this.statuses.filter(s => s.selected).map(s => s.value);
                if (statusList.indexOf("cancelled") !== -1) {
                  this.records.splice(index, 1, {
                    ...bot,
                    Status: "Cancelled",
                  });
                } else {
                  this.bots.splice(index, 1);
                }
              }
            }
          }).catch(_error => {
          });
        }
      });
    },
    pauseBot(bot) {
      const index = this._.findIndex(this.records, { ID: bot.ID });
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Pause Bot",
        text: "Are you sure you want to pause this bot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.$http.get(`${apiBaseUrl}/TradeBot/bot/pause`, {
            params: {
              BotID: bot.ID,
              MerchantID: this.merchant.ID,
              APIKey: this.merchant.APIKey,
              key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            },
          }).then(response => {
            this.$toast.fire("", response.data.reason, "success");
            // update status
            if (response.status === 200) {
              if (index !== -1) {
                const statusList = this.statuses.filter(s => s.selected).map(s => s.value);
                if (statusList.indexOf("paused") !== -1) {
                  this.records.splice(index, 1, {
                    ...bot,
                    Status: "paused",
                  });
                } else {
                  this.records.splice(index, 1);
                }
              }
            }
          }).catch(error => {
            this.$toast.fire("", error.data.reason, "error");
          });
        }
      });
    },
    resumeBot(bot) {
      const index = this._.findIndex(this.records, { ID: bot.ID });
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Resume Bot",
        text: "Are you sure you want to resume this bot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.$http.get(`${apiBaseUrl}/TradeBot/bot/resume`, {
            params: {
              BotID: bot.ID,
              MerchantID: this.merchant.ID,
              APIKey: this.merchant.APIKey,
              key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            },
          }).then(response => {
            this.$toast.fire("", response.data.reason, "success");
            // update status
            if (response.status === 200) {
              if (index !== -1) {
                const statusList = this.statuses.filter(s => s.selected).map(s => s.value);
                if (statusList.indexOf("running") !== -1) {
                  this.records.splice(index, 1, {
                    ...bot,
                    Status: "running",
                  });
                } else {
                  this.records.splice(index, 1);
                }
              }
            }
          }).catch(error => {
            this.$toast.fire("", error.data.reason, "error");
          });
        }
      });
    },
    setEditBot(bot) {
      this.$emit("setEditBot", bot);
    },
    setBotID(botID) {
      this.$emit("setBotID", botID);
    },
    initSocket() {
      const self = this;
      this.webSocket = new WebSocket("wss://artemis.cointopay.com/TradeBot/tradebot");
      this.webSocket.onmessage = (evt) => {
        if (evt) {
          const data = JSON.parse(evt.data);
          if (this.merchant && Number(data.MerchantID) === Number(this.merchant.ID)) {
            this.fetchMerchantTrades();
          }
          if (["sell", "buy"].indexOf(data.Action.toLowerCase()) !== -1) {
            this.$toast.fire("", data.Action.toUpperCase() + ": Trade posted", "success");
          }
        }
      };
      this.webSocket.onclose = (evt) => {
        if (!self.isCloseSocket) {
          self.fetchMerchantTrades();
        }
      };
    },
  },
  beforeMount() {
    this.initSocket();
  },
  beforeDestroy() {
    if (this.webSocket) {
      this.isCloseSocket = true;
      this.webSocket.close();
    }
  },
};
</script>

<style scoped lang="scss">
.dropdown-menu {
  padding: 4px 8px;
  min-width: 220px;

  a {
    display: block;
    cursor: pointer;
    margin-bottom: 1px;

    label {
      margin: 0;
      padding: 2px 4px;
      cursor: pointer;
      color: #404040;
      font-weight: normal;
    }

    :hover {
      background-color: #efefef;
    }
  }
}
</style>
