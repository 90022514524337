var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"box-body"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit())}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Market","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"Symbol"}},[_vm._v("Market")]),_c('div',{staticClass:"col-md-7"},[_c('v-select',{staticStyle:{"min-width":"180px"},attrs:{"options":_vm.symbols,"reduce":function (s) { return s.symbol; },"label":"symbol","placeholder":"Select Symbol"},model:{value:(_vm.form.Symbol),callback:function ($$v) {_vm.$set(_vm.form, "Symbol", $$v)},expression:"form.Symbol"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Algorithm","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"Algo"}},[_vm._v("Algorithm")]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Algo),expression:"form.Algo"}],staticClass:"form-control",attrs:{"id":"Algo"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Algo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"DayTrading"}},[_vm._v("DayTrading")]),_c('option',{attrs:{"value":"MovingAverageCrossover"}},[_vm._v("MovingAverageCrossover")]),_c('option',{attrs:{"value":"RelativeStrengthIndex"}},[_vm._v("RelativeStrengthIndex")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Order Type","rules":"required","tag":"div","vid":"OrderType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"OrderType"}},[_vm._v("Order Type")]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.OrderType),expression:"form.OrderType"}],staticClass:"form-control",attrs:{"id":"OrderType"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "OrderType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"limit"}},[_vm._v("Limit")]),_c('option',{attrs:{"value":"market"}},[_vm._v("Market")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Actions","rules":"required","tag":"div","vid":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"Actions"}},[_vm._v("Actions")]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Actions),expression:"form.Actions"}],staticClass:"form-control",attrs:{"id":"Actions"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Actions", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(_vm.form.OrderType === 'limit')?[_c('option',{attrs:{"value":"sell"}},[_vm._v("Sell")]),_c('option',{attrs:{"value":"buy"}},[_vm._v("Buy")])]:_vm._e(),(_vm.form.OrderType === 'market')?[_c('option',{attrs:{"value":"sellbuy"}},[_vm._v("Buy and Sell")])]:_vm._e()],2),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),(_vm.form.OrderType === 'market' || (_vm.form.OrderType === 'limit' && _vm.form.Actions === 'sell'))?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Amount","rules":_vm.baseCurrencyAmtRules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"BaseCurrencyAmt"}},[_vm._v(_vm._s(_vm.getCurrency(0))+" Amount")]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.BaseCurrencyAmt),expression:"form.BaseCurrencyAmt"}],staticClass:"form-control",attrs:{"id":"BaseCurrencyAmt"},domProps:{"value":(_vm.form.BaseCurrencyAmt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "BaseCurrencyAmt", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}):_vm._e(),(_vm.form.OrderType === 'market' || (_vm.form.OrderType === 'limit' && _vm.form.Actions === 'buy'))?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Amount","rules":_vm.quoteCurrencyAmtRules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"QuoteCurrencyAmt"}},[_vm._v(_vm._s(_vm.getCurrency(1))+" Amount")]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.QuoteCurrencyAmt),expression:"form.QuoteCurrencyAmt"}],staticClass:"form-control",attrs:{"id":"QuoteCurrencyAmt"},domProps:{"value":(_vm.form.QuoteCurrencyAmt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "QuoteCurrencyAmt", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Risk Profile","rules":"required","tag":"div","vid":"RiskProfile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"RiskProfile"}},[_vm._v("Risk Profile")]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.RiskProfile),expression:"form.RiskProfile"}],staticClass:"form-control",attrs:{"id":"RiskProfile"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "RiskProfile", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"low"}},[_vm._v("low")]),_c('option',{attrs:{"value":"high"}},[_vm._v("high")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),(_vm.form.RiskProfile === 'low')?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Segments","rules":_vm.segementsRules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"Segments"}},[_vm._v("Segments")]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Segments),expression:"form.Segments"}],staticClass:"form-control",attrs:{"id":"Segments","type":"number","min":"1"},domProps:{"value":(_vm.form.Segments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Segments", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Immediate","rules":"required","tag":"div","vid":"Immediate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"Immediate"}},[_vm._v("Immediate")]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Immediate),expression:"form.Immediate"}],staticClass:"form-control",attrs:{"id":"Immediate"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Immediate", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Yes")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),(_vm.form.OrderType === 'market' && _vm.form.Immediate === '0' || _vm.form.Immediate === 'false')?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Step in %","rules":"required|min_value:0","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"InitialPercentage"}},[_vm._v("Step in % diff")]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.InitialPercentage),expression:"form.InitialPercentage"}],staticClass:"form-control",attrs:{"id":"InitialPercentage"},domProps:{"value":(_vm.form.InitialPercentage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "InitialPercentage", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}):_vm._e(),(_vm.form.OrderType === 'market')?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Trade Trigger","rules":"required_if:OrderType,market|min_value:0.25","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"TradeTrigger"}},[_vm._v("Trade Trigger %")]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.TradeTrigger),expression:"form.TradeTrigger"}],staticClass:"form-control",attrs:{"id":"TradeTrigger","type":"text"},domProps:{"value":(_vm.form.TradeTrigger)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "TradeTrigger", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Profit Percentage","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"ProfitPercentage"}},[_vm._v("Profit Percentage")]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.ProfitPercentage),expression:"form.ProfitPercentage"}],staticClass:"form-control",attrs:{"id":"ProfitPercentage","type":"number","min":"0"},domProps:{"value":(_vm.form.ProfitPercentage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "ProfitPercentage", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Abort Condition","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-5",attrs:{"for":"AbortCondition"}},[_vm._v("Abort Condition")]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.AbortCondition),expression:"form.AbortCondition"}],staticClass:"form-control",attrs:{"id":"AbortCondition"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "AbortCondition", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"5"}},[_vm._v("5% loss")]),_c('option',{attrs:{"value":"10"}},[_vm._v("10% loss")]),_c('option',{attrs:{"value":"30"}},[_vm._v("30% loss")]),_c('option',{attrs:{"value":"60"}},[_vm._v("60% loss")]),_c('option',{attrs:{"value":"90"}},[_vm._v("90% loss")]),_c('option',{attrs:{"value":"none"}},[_vm._v("none")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"text-right"},[(_vm.editBot)?_c('button',{staticClass:"btn btn-danger btn-flat mr-8",attrs:{"disabled":_vm.isLoading,"type":"button"},on:{"click":function($event){return _vm.cancelEdit()}}},[_vm._v(" Cancel ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary btn-flat",attrs:{"type":"submit","disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.editBot ? 'Update' : 'Create')+" "),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin ml-8"})]):_vm._e()])])],1)]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-header"},[_c('h3',{staticClass:"box-title"},[_vm._v("TradingBot parameters")])])}]

export { render, staticRenderFns }